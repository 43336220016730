<style>
.branding-bar {
	width: 100%;
	height: 1vh;
	background: var(--color--primary);
	background-size: cover;
	margin: 0;
	padding: 0;
}
</style>

<div class="branding-bar"></div>

